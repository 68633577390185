// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CountryForAddressFormFragment = { __typename: 'Country', countryCode: string, displayCountry: string, displayFirstAdministrativeAreaLabel?: string | null, displayPostalCodeLabel: string, displayCityLabel: string, firstAdministrativeAreas: Array<{ __typename: 'AdministrativeArea', countryCode: string, code: string, displayName: string }> };

export type CountriesForAddressFormQueryVariables = Types.Exact<{
  filter: Types.CountryFilter;
}>;


export type CountriesForAddressFormQuery = { __typename: 'Query', countries: Array<{ __typename: 'Country', countryCode: string, displayCountry: string, displayFirstAdministrativeAreaLabel?: string | null, displayPostalCodeLabel: string, displayCityLabel: string, firstAdministrativeAreas: Array<{ __typename: 'AdministrativeArea', countryCode: string, code: string, displayName: string }> }> };

export const CountryForAddressFormFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CountryForAddressForm"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Country"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"countryCode"}},{"kind":"Field","name":{"kind":"Name","value":"displayCountry"}},{"kind":"Field","name":{"kind":"Name","value":"displayFirstAdministrativeAreaLabel"}},{"kind":"Field","name":{"kind":"Name","value":"displayPostalCodeLabel"}},{"kind":"Field","name":{"kind":"Name","value":"displayCityLabel"}},{"kind":"Field","name":{"kind":"Name","value":"firstAdministrativeAreas"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"countryCode"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]}}]} as unknown as DocumentNode;
export const CountriesForAddressFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CountriesForAddressForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CountryFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"countries"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CountryForAddressForm"}}]}}]}},...CountryForAddressFormFragmentDoc.definitions]} as unknown as DocumentNode;
export type CountriesForAddressFormComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CountriesForAddressFormQuery, CountriesForAddressFormQueryVariables>, 'query'> & ({ variables: CountriesForAddressFormQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CountriesForAddressFormComponent = (props: CountriesForAddressFormComponentProps) => (
      <ApolloReactComponents.Query<CountriesForAddressFormQuery, CountriesForAddressFormQueryVariables> query={CountriesForAddressFormDocument} {...props} />
    );
    

/**
 * __useCountriesForAddressFormQuery__
 *
 * To run a query within a React component, call `useCountriesForAddressFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesForAddressFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesForAddressFormQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountriesForAddressFormQuery(baseOptions: Apollo.QueryHookOptions<CountriesForAddressFormQuery, CountriesForAddressFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesForAddressFormQuery, CountriesForAddressFormQueryVariables>(CountriesForAddressFormDocument, options);
      }
export function useCountriesForAddressFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesForAddressFormQuery, CountriesForAddressFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesForAddressFormQuery, CountriesForAddressFormQueryVariables>(CountriesForAddressFormDocument, options);
        }
export function useCountriesForAddressFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountriesForAddressFormQuery, CountriesForAddressFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountriesForAddressFormQuery, CountriesForAddressFormQueryVariables>(CountriesForAddressFormDocument, options);
        }
export type CountriesForAddressFormQueryHookResult = ReturnType<typeof useCountriesForAddressFormQuery>;
export type CountriesForAddressFormLazyQueryHookResult = ReturnType<typeof useCountriesForAddressFormLazyQuery>;
export type CountriesForAddressFormSuspenseQueryHookResult = ReturnType<typeof useCountriesForAddressFormSuspenseQuery>;
export type CountriesForAddressFormQueryResult = Apollo.QueryResult<CountriesForAddressFormQuery, CountriesForAddressFormQueryVariables>;