import React, { Component } from 'react'
import { InputProps, RenderSuggestionParams, SuggestionSelectedEventData } from 'react-autosuggest'
import makeAsyncScriptLoader from 'react-async-script'
import throttle from 'lodash/throttle'
import classnames from 'classnames'
import { BubbleLoader, TextFieldProps, Typeahead, autosuggestHighlight } from '@deal/components'
import config from '#src/app/config'
import poweredByGoogle from '../../assets/images/powered-by-google.png'
import styles from './styles.css'

interface AddressTypeaheadProps {
  // the google library added by makeAsyncScriptLoader
  google: typeof google
  inputProps: InputProps<google.maps.places.AutocompletePrediction>
  textFieldProps: TextFieldProps
  countryCode: string
  onAddressSelect: (place: google.maps.places.PlaceResult) => void
}

interface AddressTypeaheadState {
  suggestions: google.maps.places.AutocompletePrediction[]
}

class AddressTypeahead extends Component<AddressTypeaheadProps, AddressTypeaheadState> {
  typeaheadRef: React.RefObject<HTMLInputElement>
  sessionToken?: google.maps.places.AutocompleteSessionToken
  throttledOnSuggestionsFetchRequested: typeof AddressTypeahead.prototype.onSuggestionsFetchRequested

  constructor(props: AddressTypeaheadProps) {
    super(props)

    this.state = { suggestions: [] }

    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.throttledOnSuggestionsFetchRequested = throttle(this.onSuggestionsFetchRequested, 100)
    this.getSuggestionValue = this.getSuggestionValue.bind(this)
    this.renderSuggestion = this.renderSuggestion.bind(this)

    this.typeaheadRef = React.createRef()
  }

  public componentDidUpdate(prevProps: AddressTypeaheadProps) {
    if (prevProps.google !== this.props.google) {
      this.sessionToken = new google.maps.places.AutocompleteSessionToken()
    }
  }

  public onGoogleFetch = (
    suggestions: google.maps.places.AutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus
  ) => {
    if (status !== this.props.google.maps.places.PlacesServiceStatus.OK) {
      return
    }
    this.setState({ suggestions: suggestions || [] })
  }

  // Called every time we need to update suggestions
  public onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    const { google } = this.props

    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const service = new google.maps.places.AutocompleteService()
    service.getPlacePredictions(
      {
        componentRestrictions: { country: this.props.countryCode },
        input: value,
        sessionToken: this.sessionToken,
        types: ['address']
      },
      this.onGoogleFetch
    )
  }

  // Called every time we need to clear suggestions
  public onSuggestionsClearRequested() {
    this.setState({ suggestions: [] })
  }

  public getSuggestionValue = (suggestion: any) => {
    return suggestion.description
  }

  // Update the value on select
  public onSuggestionSelected = (
    _event: React.FormEvent<any>,
    data: SuggestionSelectedEventData<any>
  ) => {
    // typeaheadRef has to be defined at this point
    const service = new this.props.google.maps.places.PlacesService(this.typeaheadRef.current!)

    service.getDetails({ placeId: data.suggestion.place_id }, (place, status) => {
      if (status !== this.props.google.maps.places.PlacesServiceStatus.OK) {
        // TODO @fguerra throw error
        return
      }

      place && this.props.onAddressSelect(place)
    })
  }

  private renderSuggestionsContainer({ containerProps, children }: any) {
    return children ? (
      <div
        {...containerProps}
        className={classnames(containerProps.className, styles.suggestionContainer)}
        data-testid="order-address-typeahead"
      >
        {children}
        <div className={styles.poweredByGoogle}>
          <img src={poweredByGoogle} alt="Powered by Google" />
        </div>
      </div>
    ) : null
  }

  private renderSuggestion(suggestion: any, data: RenderSuggestionParams) {
    const rawText = this.getSuggestionValue(suggestion)
    const parts = autosuggestHighlight.parse(
      rawText,
      autosuggestHighlight.match(rawText, data.query)
    )
    const rendered = parts.map(part => {
      if (part.highlight) {
        return <b key={part.text}>{part.text}</b>
      } else {
        return part.text
      }
    })
    return <div>{rendered}</div>
  }

  public render() {
    if (!this.props.google) {
      return <BubbleLoader />
    }

    return (
      <Typeahead
        ref={this.typeaheadRef}
        label="Address Line 1"
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.throttledOnSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={this.props.inputProps}
        textFieldProps={this.props.textFieldProps}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        disableFocusedStyles
      />
    )
  }
}

const GOOGLE_API_KEY = config.get('google.api_key')

export default makeAsyncScriptLoader(
  AddressTypeahead,
  `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
  { globalName: 'google' }
)
