import React from 'react'
import classnames from 'classnames'
import WarningIcon from './warning.svg'
import styles from './styles.css'

interface WarningWithMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  type: 'warning' | 'error'
  header: string
  text: string
  containerClassName?: string
}

const WarningWithMessage: React.FC<React.PropsWithChildren<WarningWithMessageProps>> = ({
  type,
  header,
  text,
  containerClassName,
  ...rest
}) => {
  return (
    <div className={classnames(styles.container, styles[type], containerClassName)} {...rest}>
      <div className={styles.iconContainer}>
        <WarningIcon />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.header}>{header}</div>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  )
}

export default WarningWithMessage
