import React from 'react'
import { Select, SelectProps } from '@deal/components'

interface AdministrativeArea {
  code: string
  displayName: string
}

interface Props extends Partial<SelectProps> {
  label?: string
  placeholder?: string
  areas: AdministrativeArea[]
  showValidation?: boolean
  selectProps?: React.SelectHTMLAttributes<HTMLSelectElement>
}

const AdministrativeAreaSelector: React.FC<Props> = ({
  label,
  placeholder,
  areas,
  selectProps,
  showValidation,
  value,
  ...props
}) => {
  const isValid = areas.some(area => area.code === value)

  return (
    <Select
      {...props}
      label={label || 'state'}
      value={value}
      errorText={showValidation && !isValid ? `${label || 'State'} is required` : undefined}
      selectProps={selectProps}
      options={[
        { value: '', label: placeholder || 'Please choose one', disabled: true },
        ...areas.map(area => ({
          label: area.displayName,
          value: area.code
        }))
      ]}
    />
  )
}

export default AdministrativeAreaSelector
