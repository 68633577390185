import React, { PureComponent } from 'react'
import { Select, SelectProps } from '@deal/components'
import { CountryFragment } from '../../queries/Countries.generated'

interface Props extends Omit<Partial<SelectProps>, 'options'> {
  countries: CountryFragment[]
}

class CountrySelector extends PureComponent<Props> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    const { label, countries, value, ...props } = this.props

    return (
      <Select
        {...props}
        label={label || 'Country'}
        value={value || ''}
        options={[
          {
            value: '',
            label: 'Please select a country',
            disabled: true
          },
          ...countries.map(country => ({
            value: country.countryCode,
            label: country.displayCountry
          }))
        ]}
      />
    )
  }
}

export default CountrySelector
