import React, { Component } from 'react'
import { Checkbox, TextField } from '@deal/components'
import WarningWithMessage from '#src/app/components/WarningWithMessage'
import AddressForm, { Address } from '../AddressForm'
import styles from './styles.css'

export interface ShippingAddress extends Address {
  reusableAddress: boolean
  addresseeName: string
}

interface ShippingAddressFormProps {
  shippingAddress: ShippingAddress
  onShippingAddressChanged: (newAddress: ShippingAddress) => void
  showValidation: boolean
  showUnverifiedAddressWarning: boolean
  enableSaveAddress: boolean
}

export default class ShippingAddressForm extends Component<ShippingAddressFormProps> {
  constructor(props: ShippingAddressFormProps) {
    super(props)

    this.handleShippingAddressFieldChanged = this.handleShippingAddressFieldChanged.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
  }

  private handleShippingAddressFieldChanged(
    fieldName: keyof ShippingAddress,
    fieldValue: ShippingAddress[keyof ShippingAddress]
  ) {
    const newAddress: ShippingAddress = {
      ...this.props.shippingAddress,
      [fieldName]: fieldValue
    }

    this.props.onShippingAddressChanged(newAddress)
  }

  private handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target

    this.handleShippingAddressFieldChanged(name as keyof Address, value)
  }

  private handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target

    /**
     * CheckIt's validation doesn't properly short circuit the form.checkValidity() call that happens within
     * the shipping container. The html5 api will respect the setCustomValidity on the field so we handle
     * the name input separately. This label isn't actually displayed, but might as well keep the errors consistent.
     */
    if (!value.trim().includes(' ')) {
      event.target.setCustomValidity('Please enter your full name')
    } else {
      event.target.setCustomValidity('')
    }

    this.handleShippingAddressFieldChanged(name as keyof Address, value)
  }

  private handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target

    this.handleShippingAddressFieldChanged(name as keyof Address, checked)
  }

  public render() {
    const { shippingAddress, showValidation } = this.props
    const isFullNameValid = this.props.shippingAddress.addresseeName.trim().includes(' ')

    return (
      <div>
        <div className={styles.row}>
          <div className={styles.columnFull}>
            <TextField
              name="addresseeName"
              label="Full name"
              errorText={
                showValidation && !isFullNameValid ? 'Please enter your full name' : undefined
              }
              onChange={this.handleNameChange}
              value={shippingAddress.addresseeName}
            />
          </div>
        </div>

        <AddressForm
          address={shippingAddress}
          onAddressChanged={newAddress => {
            this.props.onShippingAddressChanged({
              ...shippingAddress,
              ...newAddress
            })
          }}
          showValidation={showValidation}
        />

        {this.props.enableSaveAddress && (
          <div className={styles.saveAddress}>
            <Checkbox
              name="reusableAddress"
              checked={shippingAddress.reusableAddress}
              onChange={this.handleCheckboxChange}
            >
              Save this address for future purchases
            </Checkbox>
          </div>
        )}

        {this.props.showUnverifiedAddressWarning && (
          <WarningWithMessage
            type="warning"
            header={'Review your address'}
            text={`We don't recognize this address. Please double check the street address, city, state, and zip code. If you're sure it's correct, click "Continue" below.`}
            containerClassName={styles.verifyAddressWarningContainer}
            data-testid="review-address-warning"
          />
        )}
      </div>
    )
  }
}
