import React from 'react'
import { ChangeEvent } from 'react-autosuggest'
import { BubbleLoader, PhoneField, TextField } from '@deal/components'
import CountrySelector from '#src/app/components/CountrySelector'
import AdministrativeAreaSelector from '#src/app/components/AdministrativeAreaSelector'
import { useCountriesForAddressFormQuery } from './AddressForm.generated'
import AddressTypeahead from '../../routes/checkout/components/AddressTypeahead'
import styles from './styles.css'

export interface Address
  extends Partial<{
    line1: string
    line2: string
    city: string
    state: string
    postalCode: string
    country: string
    phoneNumber: string
  }> {}

export const isPoBoxAddress = (address?: { line1?: string | undefined | null }) => {
  const poBoxVariations = ['po box', 'p.o. box', 'pobox']
  const lowerCaseLine1 = address?.line1?.toLowerCase() ?? ''
  return poBoxVariations.some(variation => lowerCaseLine1.includes(variation))
}

interface AddressFormProps {
  showValidation?: boolean
  address: Address
  onAddressChanged: (newAddress: Address) => void
  billingAddressPurpose?: boolean
}

const AddressForm: React.FC<AddressFormProps> = ({
  address,
  showValidation,
  billingAddressPurpose,
  onAddressChanged
}) => {
  const countryFilter = billingAddressPurpose
    ? {
        billingSupported: true
      }
    : {
        shippingSupported: true
      }

  const { data, loading } = useCountriesForAddressFormQuery({
    variables: { filter: countryFilter },
    onCompleted: ({ countries: returnedCountries }) => {
      // Find the currently specified country
      const currentCountry = returnedCountries.some(
        country => country.countryCode === address.country
      )

      /**
       * If the country currently specified is not supported, default to the first returned country
       * We typically default to the order shipping country or 'US' so this is unlikely to happen
       */
      if (!currentCountry) {
        onAddressChanged({
          ...address,
          country: returnedCountries[0].countryCode
        })
      }
    }
  })

  const lookupCountry = data?.countries.find(country => country.countryCode === address.country)

  const handleAddressSelect = (place: any) => {
    const addressComponents = place.address_components as any[]

    const line1 = addressComponents
      .filter(c => c.types.includes('street_number') || c.types.includes('route'))
      .map(part => part.long_name)
      .join(' ')

    const city = addressComponents.find(
      c => c.types.includes('sublocality_level_1') || c.types.includes('locality')
    )?.long_name

    const state = addressComponents.find(c =>
      c.types.includes('administrative_area_level_1')
    )?.short_name

    const postalCode = addressComponents.find(c => c.types.includes('postal_code'))?.long_name

    onAddressChanged({
      line1,
      city,
      state,
      postalCode,
      country: address.country
    })
  }

  const handleFormValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const name = event.target.name
    const value = event.target.value
    onAddressChanged({
      ...address,
      [name]: value
    })
  }

  const handlePhoneNumberChange = (phone: string) => {
    onAddressChanged({
      ...address,
      phoneNumber: phone
    })
  }

  if (loading || !data) {
    return <BubbleLoader />
  }

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.column}>
          <CountrySelector
            value={lookupCountry?.countryCode || ''}
            onChange={handleFormValueChange}
            countries={data.countries}
            name="country"
            selectProps={{
              autoComplete: 'country'
            }}
          />
        </div>
      </div>
      {lookupCountry && (
        <>
          <div className={styles.row}>
            <div className={styles.column}>
              <AddressTypeahead
                textFieldProps={{
                  name: 'line1',
                  label: 'Address line 1',
                  placeholder: 'Street Address, c/o',
                  errorText:
                    showValidation && !address.line1?.trim()
                      ? 'Address is required'
                      : !billingAddressPurpose && isPoBoxAddress(address)
                      ? 'PO Box is not allowed'
                      : undefined
                }}
                inputProps={{
                  onChange: (_event: React.FormEvent<HTMLElement>, params?: ChangeEvent) => {
                    if (params) {
                      onAddressChanged({
                        ...address,
                        line1: params.newValue
                      })
                    }
                  },
                  value: address.line1 || '',
                  autoComplete: 'address-line1'
                }}
                countryCode={address.country || ''}
                onAddressSelect={handleAddressSelect}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <TextField
                name="line2"
                label="Address line 2 (optional)"
                placeholder="Apartment, suite, unit, floor, etc."
                value={address.line2}
                onChange={handleFormValueChange}
                inputProps={{
                  autoComplete: 'address-line2'
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column6}>
              <TextField
                name="city"
                label={lookupCountry?.displayCityLabel || ''}
                value={address.city}
                errorText={showValidation && !address.city?.trim() ? 'City is required' : undefined}
                onChange={handleFormValueChange}
                inputProps={{
                  autoComplete: 'address-level2'
                }}
              />
            </div>
            <div className={styles.column6}>
              {!!lookupCountry?.displayFirstAdministrativeAreaLabel && (
                <AdministrativeAreaSelector
                  name="state"
                  showValidation={showValidation}
                  label={lookupCountry.displayFirstAdministrativeAreaLabel || ''}
                  areas={lookupCountry.firstAdministrativeAreas}
                  value={address.state}
                  onChange={handleFormValueChange}
                  selectProps={{
                    autoComplete: 'address-level1'
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={!billingAddressPurpose ? styles.column6 : styles.column}>
              <TextField
                name="postalCode"
                label={lookupCountry?.displayPostalCodeLabel || ''}
                value={address.postalCode}
                errorText={
                  showValidation && !address.postalCode?.trim() ? 'Zip code is required' : undefined
                }
                onChange={handleFormValueChange}
                inputProps={{
                  autoComplete: 'postal-code'
                }}
              />
            </div>
            {!billingAddressPurpose && (
              <div className={styles.column6}>
                <PhoneField
                  name="phoneNumber"
                  label="Phone number"
                  type="tel"
                  defaultValue={address.phoneNumber ? address.phoneNumber.replace('+', '') : ''}
                  errorText={
                    showValidation && !address.phoneNumber ? 'Phone number is required' : undefined
                  }
                  inputProps={{
                    autoComplete: 'tel-national'
                  }}
                  onChange={e => handlePhoneNumberChange(e.target.value.replace(/[^\d]/g, ''))}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default AddressForm
